import React, { useContext } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Transition from '../../transition'
import tw from 'twin.macro'
import styled, { css } from 'styled-components'
import { useMount } from 'react-use'
import { motion, AnimatePresence } from 'framer-motion'

import { MobileMenuContext } from '../MobileMenu/context'
import { MetaTags, Header, Footer } from '../../components'
import { media } from '../../styles/utils'
import { container } from '../../styles/global'
import { getGlobalData } from '../../utils'

import { fullWidthClassName } from 'react-remove-scroll-bar'

const Layout = ({ children, meta, isHome, isProject, title }) => {
    const mobileMenu = useContext(MobileMenuContext)

    return (
        <>
            <MetaTags {...meta} />

            <Header
                isHome={isHome}
            />

            <Wrapper
                showMenuCursor={isHome && !mobileMenu.active}
                className={fullWidthClassName}
            >
                <Transition>
				    {children}
                    {!isHome && (
                        <Footer isProject={isProject} />
                    )}
                </Transition>
            </Wrapper>
        </>
    )
}


const Wrapper = styled.div`
    ${props => {
        if (props.showMenuCursor)
            return css`
                &,
                * {
                    cursor: url(${require('../../assets/images/icons/menu-cursor-large.svg')}) 49 49, auto;
                }
            `
    }}
`

export default Layout
