import React, { useState } from 'react'

import tw from 'twin.macro'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, padding, hoverState, type } from '../../../styles/global'

const BlockPageBreak = props => {
    return (
        <Wrapper>
            <Container>
                <Grid>
                </Grid>
            </Container>
        </Wrapper>
    )
}

const Grid = styled.div`
    ${tw`border-b border-darkGrey`};
`

const Wrapper = styled.div`
    ${tw`w-full`};
`

const Container = styled.div`
    ${container}
    ${padding}

    ${media.phone`
        padding: 0;
    `}
`

export default BlockPageBreak
