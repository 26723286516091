import React, { useState } from 'react'

import tw from 'twin.macro'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { ImageOrVideo } from '../../'
import { media } from '../../../styles/utils'
import { container, padding, hoverState, type, textDot } from '../../../styles/global'

const BlockTextImage = props => {
    const { include_heading, heading, image, text } = props
    console.log(props)
    if (!text) return

    return (
        <Wrapper>
            <Container>
                <Grid>
                    <Left>
                    </Left>
                    <Right>
                        {include_heading && heading && (
                            <Heading>
                                <h3>{heading}</h3>
                            </Heading>
                        )}
                        <InternalGrid>
                            {image && (
                                <ImageWrapper
                                    className={'image-wrapper'}
                                    style={{
                                        width: `${image?.width}px`,
                                        maxWidth: '100%'
                                    }}
                                >
                                    <Image
                                        key={image?.sizes?.large}
                                        src={image?.sizes?.large}
                                    >
                                        {(src, loading) => {
                                            return (
                                                <LoadedImage
                                                    className={'loaded-image'}
                                                    src={src} 
                                                    style={{opacity: loading ? 0 : 1}}  
                                                />
                                            )
                                        }}
                                    </Image>
                                </ImageWrapper>
                            )}
                            <Description
                                className={'tiny-mce'}
                                dangerouslySetInnerHTML={{
                                    __html: text,
                                }}
                            />
                        </InternalGrid>
                    </Right>
                </Grid>
            </Container>
        </Wrapper>
    )
}

// layout

const Wrapper = styled.div`
    ${tw`w-full`};
`

const Container = styled.div`
    ${container}
    ${padding}
`

const Left = styled.div``
const Right = styled.div``
const InternalGrid = styled.div``

const Grid = styled.div`
    ${tw`flex w-full`};

    ${media.phone`
        ${tw`flex-col`};
    `}

    ${Left},
    ${Right} {
        ${tw`w-1/2`};

        ${media.phone`
            ${tw`w-full`};
        `}
    }

    ${Left} {
        padding-right: 1.5rem;

        ${media.phone`
            padding-right: 0;
        `}
    }

    ${Right} {
        padding-left: 1.5rem;

        .image-wrapper {
            margin-right: 1em;
        }

        ${media.phone`
            padding-left: 0;
        `}
    }

    ${InternalGrid} {
        display: flex;
    }
`

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
    ${tw`w-full h-auto`};
`

const ImageWrapper = styled.div`
    flex-shrink: 0;
`

const Heading = styled.div`
    h3 {
        ${textDot};
        margin-bottom: 1em;
    }
`

const Description = styled.div``

export default BlockTextImage
