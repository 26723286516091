import { createGlobalStyle } from 'styled-components'
import { media } from './utils'
import tw, { css } from 'twin.macro'

import globalType from './type'
// Re-export type
export const type = globalType

export const maxWidth = 2560

export const hoverState = css`
    opacity: 1;
    transition: 0.15s opacity ease;

    &:hover {
        opacity: 0.7;
    }
`

export const textDot = (colour) => css`
    position: relative;
    display: flex;
    align-items: center;

    &::before {
        content: '';
        width: 0.875rem;
        height: 0.875rem;
        margin-right: 0.375rem;
        border-radius: 50%;
        background-color: #8c8c8c;
        background-color: ${colour ? colour : '#8c8c8c'};
    }
`

export const GlobalStyles = createGlobalStyle`
    /* base for rem units */
    html {
        font-size: 12px;
    }

    @media (min-width: 375px) {
        html {
            font-size: calc(12px + 4 * (100vw - 375px) / 991);
        }
    }
    
    /* @media (min-width: ${maxWidth}px) {
        html {
            font-size: 16px;
        }
    } */

    html,
    body {
        ${tw`m-0 p-0 w-full h-full`};
    }

    body {
        ${tw`bg-white`};

        &, * {
            font-family: 'Helvetica LT W01 Light'!important;
            font-weight: normal;
        }
        /* -webkit-font-smoothing: antialiased; */

        * {
            ${globalType.body};
        }
    }
    
    a {
        text-decoration: none;
    }

    h1, h2, h3, h4, h5, h6 {
        font-size: 1rem;
        font-weight: normal;
        margin: 0;
    }
 
    * {
        -webkit-overflow-scrolling: touch;
    }

    .tiny-mce {
        h3 {
            ${textDot}
        }

        p, ul, ol, h3 {
            &:not(:first-child) {
                margin-top: 1em;
            }   
        }

        h3:not(:first-child) {
            margin-top: 3rem;
        }

        ul {
            list-style: disc;
            padding-left: 2rem;
        }

        ol {
            list-style: decimal;
            padding-left: 2rem;
        }

        img {
            /* width: 100%; */
        }

        ${globalType.body};

        b, strong {
            font-weight: 700;
        }

        a {
            text-decoration: underline;
            ${hoverState};
        }
    }
`

export const padding = css`
    padding-left: 3rem;
    padding-right: 3rem;
    box-sizing: border-box;

    ${media.tablet`
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    `}
`

export const container = css`
    ${tw`w-full h-full mx-auto`};
    width: ${maxWidth}px;
    max-width: 100%;
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

export const button = css`
	min-width: 14rem;
	padding: 0.85rem 2rem 1rem;
	background-color: transparent;
	border-radius: 2rem;
    ${tw`border border-darkGrey `};
    line-height: 1;
    cursor: pointer;
    transition: all 0.2s ease;

	display: flex;
	justify-content: center;
	align-items: center;
`