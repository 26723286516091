import tw from 'twin.macro'
import styled, { css } from 'styled-components'
import { media } from './utils'

/*-----------------------------------*/
/* Global Type
/*-----------------------------------*/

const type = {
    body: css`
        font-size: 0.875rem;
        font-weight: 300;
        line-height: 1.4;

        ${media.phone`
            font-size: 1.2rem;
        `}
    `,
}

export default type
