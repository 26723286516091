import React, { useState, useContext, Component, useRef } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import Form from 'react-dynamic-form'
import { required, email } from '../../utils/validators'
import tw from 'twin.macro'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import { media } from '../../styles/utils'
import { container, padding, button } from '../../styles/global'

const SignupForm = props => {
    // console.log(props)
    const signupFormRef = useRef(null)
    const [submitting, setSubmitting] = useState(false)
    const [formComplete, setFormComplete] = useState(false)
    const [error, setError] = useState(false)

    const handleSubmit = (fields, resetForm) => {
        const data = {
            name: fields.name.value,
            email: fields.email.value,
        }

        let nameSplit = data.name.split(' ')
        let firstName
        let lastName

        if (nameSplit.length === 1) {
            firstName = nameSplit[0]
        } else if (nameSplit.length >= 2) {
            firstName = nameSplit[0]
            lastName = nameSplit[1]
        }

        setSubmitting(true)

        addToMailchimp(fields.email.value, {
            FNAME: firstName,
            LNAME: lastName,
        }).then(data => {
            if (data.result == 'success') {
                setFormComplete(true)
                setSubmitting(false)
            }
            
            if (data.result == 'error') {
                setError(data?.msg)
                setSubmitting(false)
            } 
        })
    }

    return (
        <Wrapper
            formComplete={formComplete}
        >
            {formComplete && (
                <Heading className={`form-heading`}>
                    Thanks for signing up
                </Heading>
            )}

            {!formComplete && (
                <FormWrapper
                    className={'form-wrapper'}
                    formComplete={formComplete}
                    submitting={submitting}
                >
                    <Form
                        ref={signupFormRef}
                        data={signupFormFields()}
                        moveToInvalidField={false}
                        renderSubmit={false}
                        onSubmit={(fields, resetForm) =>
                            handleSubmit(fields, resetForm)
                        }
                    />

                    <Submit>
                        <button
                            className={'submit-button'}
                            disabled={submitting ? true : false}
                            onClick={() => {
                                signupFormRef.current &&
                                    signupFormRef.current.handleSubmit()
                            }}
                        >
                            Submit
                        </button>
                    </Submit>
                </FormWrapper>
            )}

            {error && (
                <Error
                    className={'form-error'}
                    dangerouslySetInnerHTML={{
                        __html: error,
                    }}
                />
            )}
        </Wrapper>
    )
}

const Heading = styled.div``
const Description = styled.div``
const Error = styled.div``
const Submit = styled.div``

const Wrapper = styled.div`
    ${tw`relative flex flex-col items-start`};

    ${Heading}, ${Description} {
        /* ${tw`text-white`}; */
        font-size: 0.875rem;

        ${media.phone`
            font-size: 1.2rem;
        `}
    }

    ${Error} {
        font-size: 0.875rem;
        margin-top: 1rem;
        color: rgb(203, 0, 0);

        ${media.phone`
            font-size: 1.2rem;
        `}
    }
`

const FormWrapper = styled.div`
    ${tw`flex w-full items-start`};
    /* margin-top: 1.5rem; */

    ${media.phone`
        ${tw`flex-wrap`};
    `}

    ${props => {
        if (props.formComplete)
            return css`
                opacity: 0;
                &, * {
                    pointer-events: none;
                }
            `
    }}

    ${props => {
        if (props.submitting)
            return css`
                opacity: 0.5;
                &, * {
                    pointer-events: none;
                }
            `
    }}

    > div {
        ${tw`w-2/3`};

        ${media.phone`
            ${tw`w-full`};
        `}
    }

    .dynamic-fields {}

    .dynamic-field {
        ${tw`w-1/2`};

        ${media.phone`
            ${tw`w-full`};
            margin-bottom: 1.5rem;
        `}

        /* &:first-child {
            padding-right: 1rem;
        }

        &:last-child {
            padding-left: 1rem;
        } */

        &, * {
            font-size: 0.875rem;
            line-height: 1;
            font-weight: 300;

            ${media.phone`
                font-size: 1.2rem;
            `}
        }

        /* error message */
        > *:nth-child(2) {
            font-size: 0.6rem;

            ${media.phone`
                font-size: 0.875rem;
            `}
        }

        input {
        }
    }

    input {
        width: 100%;
        border-radius: 0;
        appearance: none;
        box-shadow: none;
        background: transparent;

        &,
        &::placeholder {
            /* ${tw`text-white`}; */
            color: inherit;
            opacity: 1;

        }

        &:focus {
            outline: none;
        }

        ${(props) => {
            if (props.disabled)
                return css`
                    pointer-events: none;
                `
        }}
    }

    ${Submit} {
        ${tw`w-1/3`};
        /* padding-left: 1rem; */

        ${media.phone`
            margin-top: 1.5rem;
            padding-left: 0;
        `}

        button {
            display: flex;
            appearance: none;
            box-shadow: none;
            background-color: transparent;
            min-width: initial;
            /* ${tw`text-white`}; */
            font-size: 0.875rem;
            line-height: 1;
            font-weight: 300;
            margin-top: 0.075rem;

            ${media.phone`
                font-size: 1.2rem;
            `}

            &:focus {
                outline: none;
            }
        }
    }
`

class Input extends Component {
    onChange = (e) => {
        const { onChangeValue, validator } = this.props
        const value = e.target.value

        if (onChangeValue) {
            onChangeValue(value, validator)
        }
    }

    render() {
        return (
            <input onChange={this.onChange} placeholder={this?.props?.placeholder} />
        )
    }
}

export const signupFormFields = (selectOptions) => {
    return {
        fields: [
            {
                type: 'text',
                name: 'name',
                validator: required,
                errorMessage: 'This field is required',
                placeholder: 'Enter your Name',
                component: ({ value, placeholder, onChangeValue, validator }) => {
                    return (
                        <Input
                            value={value}
                            placeholder={placeholder}
                            onChangeValue={onChangeValue}
                            validator={validator}
                        />
                    )
                },
            },
            {
                type: 'email',
                name: 'email',
                validator: email,
                errorMessage: 'Please enter a valid email address',
                placeholder: 'Enter your Email',
                component: ({ value, placeholder, onChangeValue, validator }) => {
                    return (
                        <Input
                            value={value}
                            placeholder={placeholder}
                            onChangeValue={onChangeValue}
                            validator={validator}
                        />
                    )
                },
            },
        ],
    }
}

export default SignupForm
