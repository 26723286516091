import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { useMeasure } from "react-use"

const Tabs = (props) => {
    const { activeItem, items, renderItem } = props
    const [ mounted, setMounted ] = useState(false)
    const [ref, { height: wrapperHeight }] = useMeasure()
    // console.log(wrapperHeight)

    useEffect(() => {
        const timer = setTimeout(() => {
            setMounted(true)
        }, 1000)
        return () => clearTimeout(timer)
    }, [])


    const renderContent = () => {
        return items.map((item, i) => {
            return (
                <>
                    {activeItem === item && (
                        <Tab
                            key={i}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 0.75
                            }}
                        >
                            {renderItem(item)}
                        </Tab>
                    )}
                </>
            )
        })
    }

	return (
        <OuterWrapper
            initial={{ height: 'initial' }}
            animate={{ height: wrapperHeight }}
            transition={{
                duration: 0.5
            }}
        >
            <Wrapper
                ref={ref}
            >
                {renderContent()}
            </Wrapper>
        </OuterWrapper>
	)
}

const OuterWrapper = styled(motion.div)`
    overflow: hidden;
`

const Wrapper = styled.div`
    position: relative;
    width: 100%;
`

const Tab = styled(motion.div)``

export default Tabs
