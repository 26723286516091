import React, { useState, useEffect, useContext } from 'react'
export const ThemeContext = React.createContext()


const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState('dark')

    return (
        <ThemeContext.Provider
            value={{
                theme: theme,
                setTheme: setTheme,
            }}
        >
            {children}
        </ThemeContext.Provider>
    )
}

export default ThemeContext

export { ThemeProvider }
