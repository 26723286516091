import React, { useState } from 'react'

import tw from 'twin.macro'
import styled, { css } from 'styled-components'
import { forEach } from 'lodash'

import { ImageOrVideo } from '../../'
import { media } from '../../../styles/utils'
import { container, padding, hoverState, type, textDot } from '../../../styles/global'

const BlockContentGrid = props => {
    const { heading, items, layout_type } = props
    if (!items) return false
    
    // if (!media_type) return

    const renderItems = () => {
        let newItems = []

        // forEach(items, (item, i) => {
        //     console.log(item?.image?.sizes?.large)
        // })

        let rowCounter = 0

        for (let index = 0; index < items.length; index++) {
            if (index < 3) {
                rowCounter = 1
            } else if (index < 6) {
                rowCounter = 2
            } else if (index < 9) {
                rowCounter = 3
            }

            // console.log(rowCounter)
            // const element = items[index];
            // console.log(element?.image?.sizes?.large)
        }

        // console.log(rowCounter)

        return items.map((item, i) => {
            const { media_type, image, video, video_embed, small_description } = item

            return (
                <Item
                    key={i}
                    className={i}
                >
                    <ImageOrVideo
                        media_type={media_type}
                        image={image}
                        video={video}
                        video_embed={video_embed}
                    />
                    <Description
                        className={'tiny-mce'}
                        dangerouslySetInnerHTML={{
                            __html: small_description,
                        }}
                    />
                </Item>
            )
        })
    }

    return (
        <Wrapper>
            <Container>
                {heading && (
                    <Heading>
                        {heading}
                    </Heading>
                )}
                <Grid
                    layout={layout_type}
                >
                    {renderItems()}
                </Grid>
            </Container>
        </Wrapper>
    )
}

const Heading = styled.div`
    ${type.body}
    line-height: 1;
    ${textDot}
`

// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
`

const Container = styled.div`
    ${container}
    ${padding}
`

// Item

const Description = styled.div``
const Item = styled.div``

const Grid = styled.div`
    ${tw`flex flex-wrap`};
    margin-top: 3rem;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-bottom: -1.5rem;

    ${media.phone`
        /* ${tw`flex-col mx-0`}; */
    `}

    ${Item} {
        ${tw`w-1/4`};
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-bottom: 1.5rem;

        ${media.phone`
            ${tw`w-1/2`};
        `}

        .image-wrapper, .video-wrapper {
            position: relative;
            width: 100%;
            height: 30vw;
            padding-top: 0;
            overflow: hidden;

            ${media.phone`
                height: 50vw;
            `}

            .loaded-image {
                ${tw`absolute inset-0 w-full h-full`};
                object-fit: cover;
            }
        }

        &:first-child {
            ${tw`w-1/2`};
        }

        ${props => {
            if (props.layout != 'layout_2')
                return css`
                    &:nth-child(9n+1),
                    &:nth-child(9n+6),
                    &:nth-child(9n+9) {
                        ${tw`w-1/2`};
                    }
                `
        }}        

        ${Description} {
            margin-top: 1.5rem;
        }
    }
`

export default BlockContentGrid
