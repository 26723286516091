import React, { useState } from 'react'
import { Link } from 'gatsby'
import tw from 'twin.macro'
import styled, { css } from 'styled-components'
import { find } from 'lodash'
import ProgressiveImage from 'react-progressive-image'

import { media } from '../../../styles/utils'
import { container, padding, hoverState, type, bgImage, textDot } from '../../../styles/global'
import { parseACF } from '../../../utils'

const BlockProjects = props => {
    const { heading, projects, projects_data } = props
    // console.log(projects_data)
    if (!projects_data) return <></>
    
    const renderItems = () => {
        const items = projects.map((item, i) => {
            if (!item?.project) return false
            let data = find(projects_data, function(o) { return o.wordpress_id === item.project })
            if (!data) return

            return (
                <Item
                    as={Link}
                    key={i}
                    to={`/projects/${data?.slug}/`}
                >
                    <ImageWrapper>
                        <Image
                            key={data?.acf?.thumbnail_image?.id || data?.wordpress_id}
                            src={data?.acf?.thumbnail_image?.sizes?.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <BGImage 
                                        image={src} 
                                        style={{opacity: loading ? 0 : 1}}  
                                    />
                                )
                            }}
                        </Image>
                    </ImageWrapper>
                    <Description
                        dangerouslySetInnerHTML={{
                            __html: data?.acf?.thumbnail_text || data?.title,
                        }}
                    />
                </Item>
            )
        })

        return (
            <Items>
                {items}
            </Items>
        )
    }

    return (
        <Wrapper>
            <Container>
                {heading && (
                    <Heading>
                        {heading}
                    </Heading>
                )}
                {renderItems()}
            </Container>
        </Wrapper>
    )
}

// shared


// const Heading = styled.h1``
// const Subheading = styled.h2``
const Heading = styled.div`
    ${type.body}
    line-height: 1;
    ${textDot}
`

const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`

// layout

const Wrapper = styled.div`
    ${tw`w-full`}
`

const Container = styled.div`
    ${container}
    ${padding}
`

// Grid

const ImageWrapper = styled.div``
const Item = styled.div``
const Items = styled.div`
    ${tw`flex flex-wrap`};
    margin-top: 3rem;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    
    ${media.phone`
        margin-bottom: -1.5rem;
    `}

    ${Item} {
        ${tw`w-1/4`};
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        ${media.phone`
            ${tw`w-1/2`};
            margin-bottom: 1.5rem;
        `}
    }

    ${ImageWrapper} {
        ${tw`relative w-full`};
        height: 0;
        padding-bottom: 128.113879%;

        ${BGImage} {
            ${tw`absolute inset-0`};
        }
    }

    ${Description} {
        margin-top: 1.5rem;
    }
`

export default BlockProjects
