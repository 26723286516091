import React from 'react'
import styled, { css } from 'styled-components'
import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState } from '../../styles/global'
import { resolveBlock } from './utils';

const Block = (props) => {
	const { acf_fc_layout, firstBlock, lastBlock, projects_data } = props;
	// console.log(layout)
	const BlockLayout = resolveBlock(acf_fc_layout);

	if (!BlockLayout) return <></>

	return (
		<Wrapper
			className={`block_${acf_fc_layout}`}
			firstBlock={firstBlock}
			lastBlock={lastBlock}
			projects_data={projects_data}
		>
			{BlockLayout && (
				<BlockLayout {...props}/>
			)}
		</Wrapper>
	)
}

const Wrapper = styled.div`
    width: 100%;
	margin-top: 3rem;

	${props => {
		if (props.lastBlock)
			return css`
				margin-bottom: 3rem;
			`
	}}

	${props => {
		if (props.firstBlock)
			return css`
				margin-top: 1.875em;
			`
	}}
`

export default Block