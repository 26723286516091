import {
    BlockImageVideo,
    BlockText,
    BlockContentGrid,
    BlockList,
    BlockProjects,
    BlockPageBreak,
    BlockTextImage,
} from './templates'

export const resolveBlock = (layout) => {
    let block

    // Map layout strings to blocks

    const blocks = {
        image_video_block: BlockImageVideo,
        text_block: BlockText,
        list_block: BlockList,
        content_grid_block: BlockContentGrid,
        projects_block: BlockProjects,
        page_break_block: BlockPageBreak,
        text_image_block: BlockTextImage,
    }

    return blocks[layout]
}
