import React, { useState, useContext, Component, useRef } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'

import { useLocation, useMount, useUnmount, useSetState } from 'react-use'
import Form from 'react-dynamic-form'
import { required, email, phone } from '../../utils/validators'
import tw from 'twin.macro'
import styled, { css } from 'styled-components'
import { postData } from '../../services/api'

import { media } from '../../styles/utils'
import { container, padding, type } from '../../styles/global'

import arrow from '../../assets/images/icons/select-arrow.svg'
import darkArrow from '../../assets/images/icons/select-arrow-dark.svg'

const ContactForm = props => {
    const { heading, cta } = props
    // console.log(props)
    const contactFormRef = useRef(null)
    const [submitting, setSubmitting] = useState(false)
    const [formComplete, setFormComplete] = useState(false)
    const [error, setError] = useState(false)

    const handleSubmit = (fields, resetForm) => {
        const data = {
            name: fields.name.value,
            email: fields.email.value,
            phone: fields.phone.value,
            interest: fields.interest.value,
            notes: fields.project.value,
        }

        // console.log(data)

        setSubmitting(true)

        postData('/hub/contact', data)
            .then(res => {
                // console.log(res)
                if (res.success) {
                    setFormComplete(true)
                    setSubmitting(false)
                    resetForm()
                } else {
                    setError(res.errors[0])
                    setSubmitting(false)
                    resetForm()
                }
            })
            .catch(error => {
                setError(error)
                setSubmitting(false)
                resetForm()
            })
    }

    return (
        <Wrapper
            formComplete={formComplete}
        >
            <Heading className={`form-heading`}>
                We would love to learn more about your business.
            </Heading>

            {formComplete && (
                <Description className={'form-thanks'}
                    dangerouslySetInnerHTML={{
                        __html: `Thanks for your enquiry.<br /> We&#039;ll get back to you as soon as possible.`
                    }}
                >
                </Description>
            )}

            <FormWrapper
                className={'form-wrapper'}
                formComplete={formComplete}
                submitting={submitting}
            >
                <Form
                    ref={contactFormRef}
                    data={contactFormFields()}
                    moveToInvalidField={false}
                    renderSubmit={false}
                    onSubmit={(fields, resetForm) =>
                        handleSubmit(fields, resetForm)
                    }
                />

                {error && (
                    <Error
                        className={'form-error'}
                        dangerouslySetInnerHTML={{
                            __html: error,
                        }}
                    />
                )}

                <Submit>
                    <button
                        className={'submit-button'}
                        disabled={submitting ? true : false}
                        onClick={() => {
                            contactFormRef.current &&
                                contactFormRef.current.handleSubmit()
                        }}
                    >
                        Submit
                    </button>
                    {cta && (
                        <Info className={'form-cta'}>{cta}</Info>
                    )}
                </Submit>
            </FormWrapper>
        </Wrapper>
    )
}

const Heading = styled.div``
const Description = styled.div``
const Info = styled.div``
const Error = styled.div``
const Submit = styled.div``


const Wrapper = styled.div`
    ${tw`relative flex flex-col items-start`};

    ${Heading}, ${Description} {
        /* ${type.body} */
    }

    ${Heading} {
        ${props => {
            if (props.formComplete)
                return css`
                    opacity: 0;
                `
        }}
    }

    .form-thanks {
        ${tw`absolute top-0 inset-x-0 w-full`};
    }

    ${Error} {
        margin-top: 1rem;
        color: rgb(203, 0, 0);
    }
`

const FormWrapper = styled.div`
    ${tw`flex flex-col items-start`};
    margin-top: 2rem;

    ${props => {
        if (props.formComplete)
            return css`
                opacity: 0;
                &, * {
                    pointer-events: none;
                }
            `
    }}

    ${props => {
        if (props.submitting)
            return css`
                opacity: 0.5;
                &, * {
                    pointer-events: none;
                }
            `
    }}

    &,
    > div {
        width: 100%;
    }

    .dynamic-field {
        &, * {
            /* font-size: 0.875rem; */
            line-height: 1;
        }

        /* error message */
        > *:nth-child(2) {
            margin-top: 0.5rem;
            /* font-size: 0.875rem; */
        }

        &:not(:last-child) {
            margin-bottom: 2.5rem;
        }

        input, select, textarea {
            padding: 0.5rem 0;
        }
    }

    input, textarea, select {
        ${tw`border-b border-darkGrey`};
        width: 100%;
        border-radius: 0;
        appearance: none;
        box-shadow: none;
        background: transparent;

        &,
        &::placeholder {
            opacity: 1;
            color: inherit;
        }

        &:focus {
            outline: none;
        }

        ${(props) => {
            if (props.disabled)
                return css`
                    pointer-events: none;
                `
        }}
    }

    textarea {
        line-height: 1.25!important;
    }

    .select-wrapper {
        position: relative;
        width: 100%;

        select {
            position: relative;
            z-index: 1;
            background-color: transparent;
            line-height: 1.25;
        }

        img {
            position: absolute;
            right: 0;
            top: 50%;
            width: auto;
            height: 12px;
            margin-top: -6px;
        }
    }

    textarea {
        resize: none;
    }

    ${Submit} {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 3rem;

        button {
            margin-right: 2rem;
            appearance: none;
            box-shadow: none;
            background-color: transparent;
            /* font-size: 0.875rem; */
            line-height: 1;

            &:focus {
                outline: none;
            }
        }

        ${Info} {
            ${tw`font-body`};
            /* font-size: 0.875rem; */
            line-height: 1.33;
        }
    }
`

class Input extends Component {
    onChange = (e) => {
        const { onChangeValue, validator } = this.props
        const value = e.target.value

        if (onChangeValue) {
            onChangeValue(value, validator)
        }
    }

    renderInput = () => {
        const { type } = this.props
        if (type === 'textarea') {
            return (
                <textarea rows={'6'} onChange={this.onChange} placeholder={this?.props?.placeholder} />    
            )
        }
        return (
            <input onChange={this.onChange} placeholder={this?.props?.placeholder} />
        )
    }

    render() {
        return this.renderInput()
    }
}

class Select extends Component {
    onChange = e => {
        const { onChangeValue, validator } = this.props
        const value = e.target.value

        if (onChangeValue) {
            onChangeValue(value, validator)
        }
    }

    renderOptions = options => {
        return options.map((item, i) => {
            return (
                <option key={i} value={item.slug}>
                    {item.title}
                </option>
            )
        })
    }

    render() {
        const { placeholder, options } = this.props
        return (
            <div className={'select-wrapper'}>
                <select onChange={this.onChange}>
                    <option value="" disabled selected>{placeholder}</option>
                    {this.renderOptions(options)}
                </select>
                <img src={arrow}/>
            </div>
        )
    }
}

export const contactFormFields = () => {
    return {
        fields: [
            {
                type: 'text',
                name: 'name',
                validator: required,
                errorMessage: 'This field is required',
                placeholder: 'Enter your Name',
                component: ({ value, placeholder, onChangeValue, validator }) => {
                    return (
                        <Input
                            value={value}
                            placeholder={placeholder}
                            onChangeValue={onChangeValue}
                            validator={validator}
                        />
                    )
                },
            },
            {
                type: 'email',
                name: 'email',
                validator: email,
                errorMessage: 'Please enter a valid email address',
                placeholder: 'Enter your Email',
                component: ({ value, placeholder, onChangeValue, validator }) => {
                    return (
                        <Input
                            value={value}
                            placeholder={placeholder}
                            onChangeValue={onChangeValue}
                            validator={validator}
                        />
                    )
                },
            },
            {
                type: 'text',
                name: 'phone',
                validator: phone,
                errorMessage: 'Please enter a valid phone number',
                placeholder: 'Enter your Phone Number',
                component: ({ value, placeholder, onChangeValue, validator }) => {
                    return (
                        <Input
                            value={value}
                            placeholder={placeholder}
                            onChangeValue={onChangeValue}
                            validator={validator}
                        />
                    )
                },
            },
            {
                type: 'text',
                name: 'interest',
                placeholder: 'What are you interested in?',
                component: ({ value, placeholder, onChangeValue, validator }) => {
                    return (
                        <Input
                            value={value}
                            placeholder={placeholder}
                            onChangeValue={onChangeValue}
                            validator={validator}
                        />
                    )
                },
            },
            {
                type: 'text',
                name: 'project',
                placeholder: 'Tell us about your project',
                component: ({ value, placeholder, onChangeValue, validator }) => {
                    return (
                        <Input
                            value={value}
                            placeholder={placeholder}
                            onChangeValue={onChangeValue}
                            validator={validator}
                        />
                    )
                },
            },
        ],
    }
}

export default ContactForm
