import React, { useState } from 'react'

import tw from 'twin.macro'
import styled, { css } from 'styled-components'

import { ImageOrVideo } from '../../'
import { media } from '../../../styles/utils'
import { container, padding, hoverState, type } from '../../../styles/global'

const BlockText = props => {
    const { image_video, text } = props
    // console.log(props)
    if (!text) return

    return (
        <Wrapper>
            <Container>
                <Grid>
                    <Left>
                        {image_video && (
                            <ImageOrVideo
                                media_type={image_video?.media_type}
                                image={image_video?.image}
                                video={image_video?.video}
                                video_embed={image_video?.video_embed}
                            />
                        )}
                    </Left>
                    <Right>
                        <Description
                            className={'tiny-mce'}
                            dangerouslySetInnerHTML={{
                                __html: text,
                            }}
                        />
                    </Right>
                </Grid>
            </Container>
        </Wrapper>
    )
}

// layout

const Wrapper = styled.div`
    ${tw`w-full`};

    .image-wrapper, .video-wrapper {
        position: relative;
        width: 85%;
        height: 0;
        overflow: hidden;
        padding-top: 85%;
        border-radius: 50%;
        z-index: 1;

        .loaded-image {
            ${tw`absolute inset-0 w-full h-full`};
            object-fit: cover;
        }
    }
`

const Container = styled.div`
    ${container}
    ${padding}
`

const Left = styled.div``
const Right = styled.div``

const Grid = styled.div`
    ${tw`flex w-full`};

    ${media.phone`
        ${tw`flex-col`};
    `}

    ${Left},
    ${Right} {
        ${tw`w-1/2`};

        ${media.phone`
            ${tw`w-full`};
        `}
    }

    ${Left} {
        padding-right: 1.5rem;

        ${media.phone`
            margin-bottom: 3rem;
            padding-right: 0;
        `}
    }

    ${Right} {
        padding-left: 1.5rem;

        ${media.phone`
            padding-left: 0;
        `}
    }
`

const Description = styled.div``

export default BlockText
