import React, { useState } from 'react'

import ProgressiveImage from 'react-progressive-image'
import tw from 'twin.macro'
import styled, { css } from 'styled-components'
// import ReactPlayer from 'react-player'

import { media } from '../../styles/utils'
import { container, padding, hoverState, type } from '../../styles/global'

const ImageOrVideo = props => {
    const { media_type, image, video, video_embed } = props

    const getVideoURL = () => {    
        if (!video_embed) return false
        if (video_embed && video_embed.match(/src="(.+?)"/)) {
            return video_embed.match(/src="(.+?)"/)[1]
        } else {
            return false
        }
    }

    const getVideoHtml = (url) => {
        return `
            <video
                loop
                muted
                autoplay
                playsinline
                preload="auto"
            >
                <source src=${video} type="video/mp4" />
            </video>
        `
    }

    return (
        <>
            {(media_type === 'image' || !media_type) && image && (
                <ImageWrapper
                    className={'image-wrapper'}
                >
                    <Image
                        key={image?.sizes?.large}
                        src={image?.sizes?.large}
                    >
                        {(src, loading) => {
                            return (
                                <LoadedImage
                                    className={'loaded-image'}
                                    src={src} 
                                    style={{opacity: loading ? 0 : 1}}  
                                />
                            )
                        }}
                    </Image>
                </ImageWrapper>
            )}
            {media_type === 'video' && video && (
                <VideoWrapper
                    className={'video-wrapper'}
                >
                    <Video
                        key={video}
                        dangerouslySetInnerHTML={{
                            __html: getVideoHtml()
                        }}
                    />
                </VideoWrapper>
            )}
            {media_type === 'embed' && video_embed && (
                <VideoWrapper
                    className={'video-wrapper'}
                >
                    <Video
                        key={getVideoURL()}
                        url={getVideoURL()}
                        controls={false}
                        playsinline={true}
                        muted
                        playing
                        loop
                        width={'100%'}
                        height={'100%'}
                    />
                </VideoWrapper>
            )}
        </>
    )
}

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
    ${tw`w-full h-auto`};
`

const ImageWrapper = styled.div``

const VideoWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    overflow: hidden;
`

const Video = styled.div`
    width: 100%;
    overflow: hidden;

    &, video {
        ${tw`absolute inset-0 w-full h-full`};
    }

    video {
        object-fit: cover;
    }
`

export default ImageOrVideo
