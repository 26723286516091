import React, { useState, useContext } from 'react'
export const LogoSVG = () => {
    return (
        <svg
            width="98"
            height="52"
            viewBox="0 0 98 52"
            // fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0)">
                <path
                    d="M31.1869 38.5942H24.5023L23.948 38.0408V22.6059L23.389 22.0525H8.35222L7.79789 22.6059V38.0408L7.2389 38.5942H0.558988L0 38.0408V0.553388L0.558988 0H7.2389L7.79789 0.553388V14.8861L8.35222 15.4395H23.389L23.948 14.8861V0.553388L24.5023 0H31.1869L31.7412 0.553388V38.0408L31.1869 38.5942Z"
                    // fill={colour}
                />
                <path
                    d="M34.8065 11.0262H41.4911L42.0501 11.5796V27.5679C42.0501 30.3256 43.1634 32.53 46.5033 32.53C50.4023 32.53 52.0746 29.7722 52.0746 25.9124V11.5796L52.6336 11.0262H59.3182L59.8771 11.5796V38.0408L59.3182 38.5942H53.1926L52.6382 38.0408L52.0793 36.3852H51.5203C51.5203 36.3852 49.848 39.1429 44.8357 39.1429C38.1511 39.1429 34.2568 34.7343 34.2568 28.1167V11.5796L34.8065 11.0262Z"
                    // fill={colour}
                />
                <path
                    d="M76.2926 17.0905C71.8393 17.0905 70.167 20.3969 70.167 24.8102C70.167 29.2235 71.8393 32.53 76.2926 32.53C80.7505 32.53 82.4182 29.2235 82.4182 24.8102C82.4228 20.4016 80.7505 17.0905 76.2926 17.0905ZM77.9649 39.143C72.9527 39.143 70.726 36.3852 70.726 36.3852H70.167L69.608 38.0408L69.0537 38.5942H62.9281L62.3691 38.0408V0.553388L62.9281 0H69.6127L70.1717 0.553388V13.2352H70.7307C70.7307 13.2352 72.9573 10.4775 77.9696 10.4775C84.6541 10.4775 90.2207 15.9929 90.2207 24.8102C90.2161 33.6321 84.6495 39.143 77.9649 39.143Z"
                    // fill={colour}
                />
                <path
                    d="M93.8548 30.856C94.9681 30.856 95.9324 31.2387 96.7522 32.0042C97.5861 32.779 98.0007 33.7843 98.0007 35.011C98.0007 36.1593 97.5861 37.1277 96.7522 37.9163C95.9324 38.6818 94.9681 39.0645 93.8548 39.0645C92.7415 39.0645 91.7772 38.6818 90.9574 37.9163C90.1282 37.1277 89.709 36.1408 89.709 34.9602C89.709 33.7566 90.1236 32.7698 90.9574 32.0042C91.7772 31.2479 92.7415 30.8652 93.8548 30.856Z"
                    // fill={colour}
                />
                <path
                    d="M35.4639 50.1969H34.6999C34.6394 50.1969 34.5928 50.1554 34.5928 50.0908V44.7368C34.5928 44.6768 34.6347 44.6307 34.6999 44.6307H36.6005C37.397 44.6307 37.9094 44.7275 38.2541 44.9397C38.6594 45.1933 38.8457 45.6084 38.8457 46.2586C38.8457 47.6605 37.9374 47.9418 36.47 47.9418H35.5757V50.0954C35.571 50.1554 35.5244 50.1969 35.4639 50.1969ZM35.571 47.1993H36.3256C37.3877 47.1993 37.8629 47.0656 37.8629 46.2724C37.8629 45.4885 37.439 45.3639 36.4281 45.3639H35.571V47.1993Z"
                    // fill={colour}
                />
                <path
                    d="M40.1776 50.1969H39.4929C39.4323 50.1969 39.3857 50.1554 39.3857 50.0908V46.4154C39.3857 46.3555 39.4277 46.3093 39.4929 46.3093H40.1637C40.2242 46.3093 40.2708 46.3508 40.2708 46.4154V46.7613C40.4804 46.4339 40.8345 46.2586 41.3096 46.2494H41.3143C41.3702 46.2494 41.44 46.2494 41.5285 46.2586C41.5937 46.2586 41.631 46.2955 41.631 46.3647L41.6217 46.9181C41.6217 46.9503 41.6077 46.978 41.5891 46.9965C41.5705 47.0149 41.5425 47.0195 41.5146 47.0149C41.4447 47.0057 41.3841 47.0057 41.3049 47.0057C40.4618 47.0057 40.2894 47.5637 40.2894 48.4629V50.0954C40.2848 50.1554 40.2382 50.1969 40.1776 50.1969Z"
                    // fill={colour}
                />
                <path
                    d="M43.9559 50.3122C42.8006 50.3122 42.0273 49.5005 42.0273 48.2923C42.0273 47.0333 42.8192 46.1894 44.0024 46.1894C45.1577 46.1894 45.9356 47.0149 45.9449 48.237C45.9449 49.4821 45.1437 50.3122 43.9559 50.3122ZM43.9885 46.8858C43.327 46.8858 42.9497 47.393 42.9497 48.2785C42.9497 49.127 43.327 49.6112 43.9791 49.6112C44.622 49.6112 45.0086 49.1085 45.0179 48.2646C45.0226 47.7574 44.9061 47.3792 44.6686 47.1486C44.4962 46.978 44.2633 46.8858 43.9885 46.8858Z"
                    // fill={colour}
                />
                <path
                    d="M47.5411 52H46.861C46.8005 52 46.7539 51.9585 46.7539 51.8939V46.4154C46.7539 46.3554 46.7958 46.3093 46.861 46.3093H47.5411C47.6017 46.3093 47.6483 46.3508 47.6483 46.4154V46.752C47.8672 46.4108 48.2492 46.2217 48.7337 46.2217C49.5442 46.2217 50.4106 46.7613 50.4106 48.2692C50.4106 49.2746 49.8936 50.2891 48.7337 50.2891C48.2492 50.2891 47.8952 50.1139 47.6483 49.7542V51.8939C47.6483 51.9585 47.6017 52 47.5411 52ZM48.5427 46.9503C47.9557 46.9503 47.6669 47.3562 47.639 48.2277C47.625 48.7488 47.7275 49.1224 47.9418 49.3391C48.0862 49.4867 48.2865 49.5605 48.5333 49.5605C49.2461 49.5605 49.5023 48.878 49.5023 48.237C49.5023 47.6144 49.2507 46.9503 48.5427 46.9503Z"
                    // fill={colour}
                />
                <path
                    d="M53.0154 50.3122C51.8043 50.3122 51.0264 49.4959 51.0264 48.2323C51.0264 46.9918 51.809 46.1894 53.0248 46.1894C54.1753 46.1894 54.8322 46.9503 54.8322 48.2785C54.8322 48.3384 54.7902 48.3753 54.725 48.3753H51.944C51.9487 48.9471 52.1676 49.6204 53.1412 49.6204C53.3881 49.6204 53.7514 49.5559 54.0775 48.984C54.1055 48.9241 54.1614 48.9056 54.2219 48.9333L54.739 49.1408C54.7716 49.1547 54.7902 49.1731 54.7995 49.1915C54.8089 49.2192 54.7995 49.2515 54.7856 49.2746C54.4455 49.9478 53.8167 50.3122 53.0154 50.3122ZM51.972 47.7481H53.9331C53.8958 47.3977 53.7188 46.8489 52.9782 46.8489C52.4192 46.8489 52.0465 47.1809 51.972 47.7481Z"
                    // fill={colour}
                />
                <path
                    d="M56.4901 50.1969H55.8054C55.7448 50.1969 55.6982 50.1554 55.6982 50.0908V46.4154C55.6982 46.3555 55.7402 46.3093 55.8054 46.3093H56.4762C56.5367 46.3093 56.5833 46.3508 56.5833 46.4154V46.7613C56.7929 46.4339 57.1469 46.2586 57.6221 46.2494C57.6221 46.2494 57.6221 46.2494 57.6267 46.2494C57.6826 46.2494 57.7525 46.2494 57.8364 46.2586C57.9016 46.2586 57.9388 46.2955 57.9388 46.3647L57.9295 46.9181C57.9295 46.9503 57.9155 46.978 57.8969 46.9965C57.8783 47.0149 57.8503 47.0195 57.8224 47.0149C57.7525 47.0057 57.692 47.0057 57.6128 47.0057C56.7696 47.0057 56.5973 47.5637 56.5973 48.4629V50.0954C56.5973 50.1554 56.5553 50.1969 56.4901 50.1969Z"
                    // fill={colour}
                />
                <path
                    d="M60.3474 50.2568C59.6161 50.2568 59.318 49.8787 59.318 48.9517V46.918H58.7077C58.6472 46.918 58.6006 46.8765 58.6006 46.812V46.4154C58.6006 46.3554 58.6425 46.3093 58.7077 46.3093H59.318V45.3593C59.318 45.2994 59.3599 45.2533 59.4251 45.2533H60.1052C60.1658 45.2533 60.2123 45.2948 60.2123 45.3593V46.3093H60.8971C60.9576 46.3093 61.0042 46.3508 61.0042 46.4154V46.812C61.0042 46.8719 60.9623 46.918 60.8971 46.918H60.2123V48.7903C60.2123 49.2699 60.2822 49.5697 60.6269 49.5697C60.7061 49.5697 60.79 49.5559 60.9018 49.5236C60.9483 49.5097 60.9763 49.5236 60.9903 49.5328C61.0042 49.542 61.0229 49.5651 61.0229 49.6112V50.1046C61.0229 50.1969 60.967 50.2107 60.939 50.2107C60.8179 50.2245 60.5897 50.2568 60.3474 50.2568Z"
                    // fill={colour}
                />
                <path
                    d="M63.0313 52H62.3232C62.2766 52 62.2533 51.9816 62.244 51.9631C62.2347 51.9493 62.2254 51.917 62.244 51.8755L62.8962 50.1692L61.5919 46.4292C61.5779 46.3831 61.5872 46.3554 61.6012 46.3416C61.6105 46.3278 61.6338 46.3093 61.6804 46.3093H62.3745C62.435 46.3093 62.4769 46.3416 62.4956 46.3969L63.3806 49.2469L64.3496 46.3969C64.3682 46.3416 64.4101 46.3093 64.4614 46.3093H65.1787C65.2207 46.3093 65.2439 46.3278 65.2533 46.3416C65.2719 46.3647 65.2766 46.3969 65.2626 46.4338L63.8325 50.1738L63.1477 51.917C63.1291 51.9723 63.0872 52 63.0313 52Z"
                    // fill={colour}
                />
                <path
                    d="M70.503 50.3352C69.003 50.3352 67.9922 49.1731 67.9922 47.4438C67.9922 46.6091 68.2391 45.8574 68.6816 45.3271C69.1614 44.7552 69.8508 44.4555 70.6753 44.4555C72.0542 44.4555 72.6877 45.2394 72.9718 45.8989C72.9905 45.9358 72.9812 45.9635 72.9718 45.9819C72.9625 46.0003 72.9392 46.0188 72.9113 46.0372L72.2359 46.2632C72.2079 46.2724 72.1799 46.2724 72.1567 46.2586C72.1334 46.2448 72.1101 46.2217 72.1008 46.1894C71.8911 45.5761 71.4067 45.2394 70.7312 45.2394C69.6738 45.2394 69.017 46.0787 69.017 47.4299C69.017 48.7442 69.6365 49.5282 70.68 49.5282C71.5464 49.5282 72.0542 48.9656 72.1054 47.9418H70.8151C70.7545 47.9418 70.7079 47.9003 70.7079 47.8357V47.3054C70.7079 47.2455 70.7499 47.1994 70.8151 47.1994H72.8647C72.9253 47.1994 72.9718 47.2409 72.9718 47.3054V50.0862C72.9718 50.1461 72.9299 50.1923 72.8647 50.1923H72.3057C72.2405 50.1923 72.1986 50.1554 72.1986 50.0862L72.1799 49.4682C71.8399 50.0539 71.2902 50.3352 70.503 50.3352Z"
                    // fill={colour}
                />
                <path
                    d="M74.6718 50.1969H73.987C73.9265 50.1969 73.8799 50.1554 73.8799 50.0908V46.4154C73.8799 46.3555 73.9218 46.3093 73.987 46.3093H74.6578C74.7184 46.3093 74.7649 46.3508 74.7649 46.4154V46.7613C74.9746 46.4339 75.3286 46.2586 75.8037 46.2494C75.8037 46.2494 75.8037 46.2494 75.8084 46.2494C75.8643 46.2494 75.9342 46.2494 76.018 46.2586C76.0832 46.2586 76.1205 46.2955 76.1205 46.3647L76.1112 46.9181C76.1112 46.9503 76.0972 46.978 76.0786 46.9965C76.0599 47.0149 76.032 47.0195 76.004 47.0149C75.9342 47.0057 75.8736 47.0057 75.7944 47.0057C74.9513 47.0057 74.7789 47.5637 74.7789 48.4629V50.0954C74.7789 50.1554 74.7323 50.1969 74.6718 50.1969Z"
                    // fill={colour}
                />
                <path
                    d="M78.408 50.3122C77.2574 50.3122 76.4795 49.5005 76.4795 48.2923C76.4795 47.0333 77.2714 46.1894 78.4546 46.1894C79.6098 46.1894 80.3877 47.0149 80.3971 48.237C80.4017 49.4821 79.6005 50.3122 78.408 50.3122ZM78.4406 46.8858C77.7791 46.8858 77.4018 47.393 77.4018 48.2785C77.4018 49.127 77.7791 49.6112 78.4313 49.6112C79.0741 49.6112 79.4608 49.1085 79.4701 48.2646C79.4747 47.7574 79.3583 47.3792 79.1207 47.1486C78.953 46.978 78.7154 46.8858 78.4406 46.8858Z"
                    // fill={colour}
                />
                <path
                    d="M82.5582 50.2891C81.375 50.2891 81.2119 49.3668 81.2119 48.3707V46.4108C81.2119 46.3508 81.2538 46.3047 81.3191 46.3047H82.0225C82.083 46.3047 82.1296 46.3462 82.1296 46.4108V48.3569C82.1296 49.0578 82.1762 49.519 82.8144 49.519C83.6482 49.519 83.7181 48.8503 83.7181 48.1632V46.4154C83.7181 46.3554 83.76 46.3093 83.8252 46.3093H84.5286C84.5892 46.3093 84.6357 46.3508 84.6357 46.4154V50.0908C84.6357 50.1508 84.5938 50.1969 84.5286 50.1969H83.8485C83.7879 50.1969 83.7414 50.1554 83.7414 50.0908V49.6343C83.5038 50.077 83.1171 50.2891 82.5582 50.2891Z"
                    // fill={colour}
                />
                <path
                    d="M86.3917 52H85.7116C85.6511 52 85.6045 51.9585 85.6045 51.8939V46.4154C85.6045 46.3554 85.6464 46.3093 85.7116 46.3093H86.3917C86.4523 46.3093 86.4989 46.3508 86.4989 46.4154V46.752C86.7178 46.4108 87.0998 46.2217 87.5842 46.2217C88.3948 46.2217 89.2612 46.7613 89.2612 48.2692C89.2612 49.2746 88.7441 50.2891 87.5842 50.2891C87.1044 50.2891 86.7458 50.1139 86.4989 49.7542V51.8939C86.4942 51.9585 86.4523 52 86.3917 52ZM87.3886 46.9503C86.8017 46.9503 86.5128 47.3562 86.4849 48.2277C86.4756 48.7488 86.5734 49.1224 86.7877 49.3391C86.9321 49.4867 87.1324 49.5605 87.3793 49.5605C88.092 49.5605 88.3482 48.878 88.3482 48.237C88.3482 47.6144 88.0966 46.9503 87.3886 46.9503Z"
                    // fill={colour}
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="98"
                        height="52"
                        // fill={colour}
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
