import React, { useEffect } from 'react'
import { container, padding, bgImage, type } from '../../styles/global'
import { media } from '../../styles/utils'
import tw from 'twin.macro'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
// import ReactPlayer from 'react-player'

const Hero = (props) => {
    const {
        isProjectHero,
        media_type,
        image,
        video,
        content,
        largeText
    } = props

    const getVideoHtml = (url) => {
        return `
            <video
                loop
                muted
                autoplay
                playsinline
                preload="auto"
            >
                <source src=${video} type="video/mp4" />
            </video>
        `
    }

    const renderText = () => {
        if (!content) return
        return (
            <Container>
                <Grid>
                    <Right>
                        <Heading
                            largeText={largeText}
                            dangerouslySetInnerHTML={{
                                __html: content,
                            }}
                        />
                    </Right>
                </Grid>
            </Container>
        )
    }

    return (
        <Wrapper
            className={'hero-section'}
            isProjectHero={isProjectHero}
        >
            {media_type === 'image' && image && (
                <ImageWrapper>
                    <Image key={image.url} src={image.sizes.medium2}>
                        {(src, loading) => {
                            return (
                                <LoadedImage
                                    src={src}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>
                </ImageWrapper>
            )}
            {media_type === 'video' && video && (
                <Video
                    key={video}
                    dangerouslySetInnerHTML={{
                        __html: getVideoHtml()
                    }}
                />
            )}
            <Overlay
                className={'hero-overlay'}
            />
            {content && renderText()}
        </Wrapper>
    )
}

// Shared

const Heading = styled.div`
    max-width: 30rem;

    &, * {
        ${type.body}
    }

    ${props => {
        if (props.largeText)
            return css`
                max-width: 35rem;
                
                &, * {
                    font-size: 2rem;
                }
            `
    }}
`
const Subheading = styled.div``
const Description = styled.div``
const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`
const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`
const Video = styled.div``
const Overlay = styled.div``

// Layout

const Container = styled.div`
    ${container};
    ${padding};
`

// Grid system

const Left = styled.div``
const Right = styled.div``

const Grid = styled.div`
    ${tw`flex w-full justify-end`};

    > * {
        ${tw`w-full`};
    }

    ${Right} {
        ${tw`w-1/2`};
        padding-left: 1.5rem;

        ${media.phone`
            ${tw`w-full`};
            padding-left: 0;
        `}
    }
`

const ImageWrapper = styled.div``

const Wrapper = styled.div`
    ${tw`absolute inset-0 w-full h-full`};

    ${ImageWrapper}, ${Overlay} {
        ${tw`absolute inset-0 w-full h-full`};
    }

    ${LoadedImage} {
        ${tw`absolute inset-0 w-full h-full`};
        object-fit: cover;
    }

    ${(props) => {
        if (props.isProjectHero)
            return css`
                ${ImageWrapper} {
                    ${tw`fixed inset-0`};
                    bottom: 80px;
                    overflow: hidden;
                    height: initial;
                }
            `
    }}

    ${Overlay} {
        background: rgba(0,0,0,0.5);
    }

    ${Video} {
        overflow: hidden;
    }

    ${Video}, video {
        ${tw`absolute inset-0 w-full h-full`};
    }

    video {
        object-fit: cover;
    }

    ${Container} {
        ${tw`relative flex h-full items-center`};
    }
`

export default Hero
