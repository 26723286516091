import React, { useState, useContext, useEffect } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import tw from 'twin.macro'
import styled, { css } from 'styled-components'
import { MobileMenuContext } from '../MobileMenu/context'
import { motion } from 'framer-motion'

import { media } from '../../styles/utils'
import { container, padding, hoverState, type } from '../../styles/global'
import { renderLink } from '../../utils'

import { LogoSVG } from './LogoSVG'

import { ThemeContext } from '../../context/ThemeContext'
import { PageTitleContext } from '../../context/PageTitleContext'
import { useMount } from 'react-use'
import { fullWidthClassName } from 'react-remove-scroll-bar'

export const headerHeight = '6.25rem'

const Header = props => {
    const { isHome, title, headerTheme } = props
    const mobileMenu = useContext(MobileMenuContext)
    const { pageTitle } = useContext(PageTitleContext)
    const theme = useContext(ThemeContext)

    const logoVariants = {
        dark: {
            fill: 'rgb(255,255,255)',
        },
        light: {
            fill: 'rgb(0,0,0)',
        },
        grey: {
            fill: 'rgb(0,0,0)',
        }
    }

    const pageTitleVariants = {
        dark: {
            color: 'rgb(255,255,255)',
        },
        light: {
            color: 'rgb(0,0,0)',
        },
        grey: {
            color: 'rgb(0,0,0)',
        }
    }

    const menuToggleVariants = {
        dark: {
            backgroundColor: 'rgb(255,255,255)',
            color: 'rgb(0,0,0)',
        },
        light: {
            backgroundColor: 'rgb(0,0,0)',
            color: 'rgb(255,255,255)',
        },
        grey: {
            backgroundColor: 'rgb(0,0,0)',
            color: 'rgb(255,255,255)',
        }
    }

    return (
        <>
            <Wrapper
                menuActive={mobileMenu.active}
                className={fullWidthClassName}
            >
                <Container>
                    {isHome && (
                        <HomeLink
                            onClick={() => {
                                navigate('/')
                                if (mobileMenu.active) {
                                    mobileMenu.toggleActive()
                                }
                            }}
                            animate={theme?.theme}
                            variants={logoVariants}
                        >
                            <LogoSVG />
                        </HomeLink>
                    )}

                    {!isHome && pageTitle && (
                        <PageTitle
                            animate={theme?.theme}
                            variants={pageTitleVariants}
                        >
                            {pageTitle}
                        </PageTitle>
                    )}
                </Container>
            </Wrapper>
            <Wrapper
                menuActive={mobileMenu.active}
                fixed={true}
                className={fullWidthClassName}
            >
                <Container
                    fixed={true}
                >
                    <MobileNavToggle
                        isHome={isHome}
                        onClick={() => {
                            mobileMenu.toggleActive()
                        }}
                        animate={theme?.theme}
                        variants={menuToggleVariants}
                    >
                        <span>Menu</span>
                    </MobileNavToggle>
                </Container>
            </Wrapper>
        </>
    )
}

// Layout

const Wrapper = styled.div`
    ${tw`z-20 absolute top-0 inset-x-0`};
    pointer-events: none;
    /* mix-blend-mode: difference; */

    ${props => {
        if (!props.menuActive)
            return css`
                /* mix-blend-mode: difference; */
            `
    }}

    ${props => {
        if (props.fixed)
            return css`
                position: fixed;
            `
    }}
`

const Container = styled.div`
    ${container}
    ${padding}
    ${tw`relative flex items-center`};
    height: ${headerHeight};

    ${props => {
        if (props.fixed)
            return css`
                justify-content: flex-end;
            `
    }}
`

// Logo

const HomeLink = styled(motion.div)`
    height: 52px;
    pointer-events: initial;
    
    &, * {
        cursor: pointer!important;
    }

    svg {
        ${tw`h-full`};
        width: auto;
        fill: inherit;
    }
`

// PageTitle

const PageTitle = styled(motion.div)`
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1;

    ${media.phone`
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transform: translateY(-0.5em);
        left: 1.5rem;
        padding-right: 7rem;
    `}
`

// Mobile Nav Toggle

const MobileNavToggle = styled(motion.div)`
    ${tw`relative bg-black rounded-full`};
    width: 4.125rem;
    height: 4.125rem;
    pointer-events: initial;

    ${props => {
        if (props.isHome)
            return css`
                display: none;

                ${media.tablet`
                    display: block;
                `}
            `
    }}

    &, * {
        ${tw`cursor-pointer`};
    }

    span {
        ${tw`absolute top-1/2 left-1/2 uppercase`};
        transform: translate(-50%, -50%);
        font-size: 0.5625rem;
        letter-spacing: -0.02em;
    }
`

export default Header
