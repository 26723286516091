import React from 'react'
import { forEach, findLastIndex, keys, pickBy } from 'lodash'
import { useStaticQuery, navigate, graphql } from 'gatsby'

export const parseACF = (queryData, nodeName, debug) => {
    if (queryData && queryData[nodeName]) {
        const nodes = queryData[nodeName].nodes
        const acfJSON = nodes && nodes[0].acf_json

        if (!acfJSON) return

        // Console log out if debug = true

        debug && console.log(JSON.parse(acfJSON))

        // Return parsed ACF JSON

        return JSON.parse(acfJSON)
    }
}

// Group option page keys by prefix

export const groupByPrefix = (data, prefix) => {
    let items = {}

    forEach(data, (item, key) => {
        if (key.includes(prefix)) {
            items[key.replace(`${prefix}_`, '')] = item
        }
    })

    return items
}

// Get global data

export const getGlobalData = prefix => {
    const query = graphql`
        query {
            allWordpressGlobal {
                nodes {
                    acf_json
                }
            }
        }
    `

    // Fetch global data

    const globalData = parseACF(useStaticQuery(query), 'allWordpressGlobal')

    // Return global + grouped by prefix

    return {
        global: globalData,
        data: groupByPrefix(globalData, prefix),
    }
}

// Render Link

export const renderLink = (link, i) => {
    if (link.type === 'internal') {
        return (
            <div
                className="link"
                key={i}
                onClick={e => {
                    e.preventDefault()
                    if (link.url.includes('services-page')) {
                        navigate(`/services`)
                    } else if (link.url.includes('news-page')) {
                        navigate(`/news`)
                    } else {
                        navigate(`${link.url}`)
                    }
                }}
            >
                {link.title}
            </div>
        )
    } else {
        return (
            <a className="link" key={i} href={`${link.url}`} target={`_blank`}>
                {link.title}
            </a>
        )
    }
}


// Detect if last text block

export const getLastTextIndexes = (blocks) => {
    const downloadIndexes = keys(pickBy(blocks, {acf_fc_layout: 'download_block'}))
    const caseStudyIndexes = keys(pickBy(blocks, {acf_fc_layout: 'case_study_block'}))

    let lastTextIndexes = downloadIndexes.concat(caseStudyIndexes)

    for (let i = 0; i < lastTextIndexes.length; i++) {
        lastTextIndexes[i] = lastTextIndexes[i]-1;
    }

    return lastTextIndexes
}