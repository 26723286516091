import React, { useState, useEffect, useContext } from 'react'
export const PageTitleContext = React.createContext()


const PageTitleProvider = ({ children }) => {
    const [pageTitle, setPageTitle] = useState(false)

    return (
        <PageTitleContext.Provider
            value={{
                pageTitle: pageTitle,
                setPageTitle: setPageTitle,
            }}
        >
            {children}
        </PageTitleContext.Provider>
    )
}

export default PageTitleContext

export { PageTitleProvider }
