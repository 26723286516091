import React, { useState, useRef, useContext, useEffect } from 'react'
import { navigate } from 'gatsby'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import tw from 'twin.macro'
import styled, { css } from 'styled-components'
import { RemoveScrollBar } from 'react-remove-scroll-bar'
import { fullWidthClassName } from 'react-remove-scroll-bar'

import { ImageOrVideo } from '../../components'
import { container, padding, type } from '../../styles/global'
import { media } from '../../styles/utils'
import { navigation } from '../../styles/type'
import { headerHeight } from '../Header/Header'

import { LogoSVG } from '../Header/LogoSVG'
import closeIcon from '../../assets/images/icons/menu-close.svg'

import { MobileMenuContext } from '../MobileMenu/context'

const MobileMenu = (props) => {
    const { menu, media, mobileCTALink } = props
    const mobileMenuEl = useRef(null)
    const mobileMenu = useContext(MobileMenuContext)
    const [active, setActive] = useState(false)

    const onKeyDown = (event) => {
        // escape key was pressed
        if (event.keyCode === 27) {
            if (mobileMenu.active) {
                mobileMenu.toggleActive()
            }
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', onKeyDown, false)

        return () => {
            document.removeEventListener('keydown', onKeyDown, false)
        }
    }, [onKeyDown])

    useEffect(() => {
        if (mobileMenu.active) {
            setActive(true)
            disableBodyScroll(mobileMenuEl.current)
        } else {
            clearAllBodyScrollLocks()
            setActive(false)
        }
    }, [mobileMenu.active])

    const renderMenu = (menuItems, className) => {
        if (!menuItems) return
        return menuItems.map((item, i) => {
            const link = item?.link || item
            if (!link) return

            if (link.type === 'internal') {
                return (
                    <MenuItem
                        key={i}
                        onClick={() => {
                            mobileMenu.toggleActive()
                            if (!link.url) {
                                // do nothing
                            } else {
                                if (link.url.includes('projects-page')) {
                                    navigate('/projects/')
                                } else if (link.url.includes('news-page')) {
                                    navigate('/news/')
                                } else {
                                    navigate(link.url)
                                }
                            }
                        }}
                        activeClassName={'active'}
                        style={{
                            order: i < 3 ? i + 1 : i + 2,
                        }}
                    >
                        {link?.title}
                    </MenuItem>
                )
            } else {
                return (
                    <MenuItem
                        className={className && className}
                        key={i}
                    >
                        <ExternalLink
                            href={link?.url}
                            style={{
                                order: i < 3 ? i + 1 : i + 2,
                            }}
                        >
                            {link?.title}
                        </ExternalLink>
                    </MenuItem>
                )
            }
        })
    }

    return (
        <Wrapper ref={mobileMenuEl}>
            <Container>
                <Close
                    className={fullWidthClassName}                
                    onClick={() => {
                        mobileMenu.toggleActive()
                    }}
                >
                    <Icon src={closeIcon} />
                </Close>
            </Container>
            <Menu>
                {menu && renderMenu(menu, false)}
                <MenuItem
                    onClick={() => {
                        mobileMenu.toggleActive()
                        navigate('/')
                    }}
                    style={{
                        fill: 'black',
                        order: 0,
                    }}
                >
                    <LogoSVG />
                </MenuItem>
                <MenuItem
                    className={'video-menu-item'}
                    style={{
                        order: 4,
                    }}
                >
                    {/* {active && ( */}
                    <ImageOrVideo
                        media_type={media?.media_type}
                        image={media?.image}
                        video={media?.video}
                        video_embed={media?.video_embed}
                    />
                    {/* )} */}
                </MenuItem>
                {mobileCTALink && renderMenu([mobileCTALink], 'mobile-cta-link')}
            </Menu>
        </Wrapper>
    )
}

// Shared

const Icon = styled.img``

const Wrapper = styled.div`
    ${tw`relative w-full h-full`};

    ${media.tablet`
        ${tw`bg-white`};
        overflow-y: auto;
    `}
`

const Container = styled.div`
    ${container};
    ${padding};
    ${tw`absolute top-0 flex justify-end`};
    height: initial;

    ${media.tablet`
        pointer-events: none;
    `}
`

const Close = styled.div`
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.125rem;
    height: 4.125rem;
    pointer-events: initial;

    ${Icon} {
        width: 2rem;
        height: 2rem;
    }
`

const ExternalLink = styled.a``
const MenuItem = styled.div``

const Menu = styled.div`
    ${tw`flex flex-row flex-wrap h-full items-start`};
    margin-left: -1px;
    margin-right: -1px;

    ${media.tablet`
        flex-direction: column;
        flex-wrap: nowrap;
        margin: 0;
    `}

    // Logo
    ${MenuItem} svg {
        width: 8rem;
        height: auto;
    }

    ${MenuItem} {
        ${tw`flex justify-center items-center w-1/3 h-1/3  border-black border-r`};
        font-size: 2rem;
        letter-spacing: -0.02em;
        line-height: 1;
        cursor: pointer;

        &:nth-child(3),
        &:nth-child(4) {
            ${tw`border-t border-b`};

            ${media.tablet`
                border-top: none;
            `}
        }

        &.video-menu-item {
            ${tw`border-t border-b`};
            cursor: initial;
            overflow: hidden;

            .video-wrapper {
                position: relative;
                width: 100%;
                height: 100%;
                padding-top: 0;
            }
        }

        &.mobile-cta-link {
            display: none;
            order: 99!important;
        }

        ${media.tablet`
            width: 100%;
            height: initial;
            padding: 3rem 0;
            border-right: none;
            border-bottom: 1px solid black;

            &:last-child {
                border-bottom: none;
            }

            &.video-menu-item {
                display: none;
            }

            &.mobile-cta-link {
                display: flex;
                order: 99!important;

                ${ExternalLink} {
                    min-width: 14rem;
                    padding: 0.85rem 2rem 1rem;
                    background-color: black;
                    border-radius: 2rem;
                    line-height: 1;
                    cursor: pointer;
                    color: white;
                    font-size: 2rem;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        `}
    }
`

export default MobileMenu
