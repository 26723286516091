import React from 'react'

import { container, padding } from '../../styles/global'
import { media } from '../../styles/utils'
import tw from 'twin.macro'
import styled, { css } from 'styled-components'

const PageHeader = props => {
    const { heading, text } = props

    return (
        <Wrapper
            className={'page-header'}
        >
            <Grid>
                <Left>
                    {heading && (
                        <Heading
                            dangerouslySetInnerHTML={{
                                __html: heading,
                            }}
                        />                        
                    )}
                </Left>
                <Right>
                    {text && (
                        <Description
                            dangerouslySetInnerHTML={{
                                __html: text,
                            }}
                        />
                    )}
                </Right>
            </Grid>
        </Wrapper>
    )
}

const Heading = styled.h1``
const Subheading = styled.div``
const Description = styled.div``

const Left = styled.div``
const Right = styled.div``

const Grid = styled.div`
    ${tw`flex -mx-4`};

    ${media.phone`
        ${tw`flex-col mx-0`};
    `}

    ${Left}, ${Right} {
        ${tw`w-1/2 px-4`};

        ${media.phone`
            ${tw`w-full px-0`};
        `}
    }

    ${Right} {
        ${media.phone`
            ${tw`mt-8`};
        `}
    }
`


const Wrapper = styled.div`
    ${tw`w-full`};
    padding-top: 8rem;
    padding-bottom: 11rem;

    ${media.phone`
        padding-top: 6rem;
    `}

    ${Heading}, ${Description} {
        /* ${tw``}; */
        font-weight: 300;
        font-size: 2rem;
        line-height: 1.1;

        p:not(:first-child) {
            margin-top: 1em;
        }
    }
`

export default PageHeader
