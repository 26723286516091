import React, { useState } from 'react'

import tw from 'twin.macro'
import styled, { css } from 'styled-components'

import { ImageOrVideo } from '../../'
import { media } from '../../../styles/utils'
import { container, padding, hoverState, type } from '../../../styles/global'

const BlockImageVideo = props => {
    const { media_type, image, video, video_embed } = props
    // if (!media_type) return

    return (
        <Wrapper
            noMedia={!media_type}
        >
            <Container>
                <Grid>
                    <Left></Left>
                    <Right>
                        <ImageOrVideo
                            media_type={media_type}
                            image={image}
                            video={video}
                            video_embed={video_embed}
                        />
                    </Right>
                </Grid>
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    ${tw`w-full`}

    ${props => {
        if (props.noMedia)
            return css`
                background-color: lightgray;
                height: 500px;
            `
    }}

    .image-wrapper {
        .loaded-image {}
    }

    .video-wrapper {

    }
`

const Container = styled.div`
    ${container}
    ${padding}
`

const Left = styled.div``
const Right = styled.div``

const Grid = styled.div`
    ${tw`flex w-full`};

    ${media.phone`
        ${tw`flex-col`};
    `}

    ${Left},
    ${Right} {
        ${tw`w-1/2`};

        ${media.phone`
            ${tw`w-full`};
        `}
    }

    ${Left} {
        padding-right: 1.5rem;

        ${media.phone`
            margin-bottom: 3rem;
            padding-right: 0;
        `}
    }

    ${Right} {
        padding-left: 1.5rem;

        ${media.phone`
            padding-left: 0;
        `}
    }
`

export default BlockImageVideo
