import React, { useState } from 'react'
import PropTypes from 'prop-types'
import GoogleMapReact from 'google-map-react'
import { GOOGLE_MAPS_KEY } from '../../constants'
import { mapTheme } from '../../styles/map'
import { useLocation } from 'react-use'
import tw from 'twin.macro'
import styled, { css } from 'styled-components'

import { media } from '../../styles/utils'

import mapMarker from '../../assets/images/logos/hubpropertygroup-logo-dark.svg'

const GoogleMap = props => {
    const location = useLocation()
    const { map, padding } = props
    if (!location || !map) return

    const mapProps = {
        defaultZoom: 15,
        defaultCenter: {
            lat: parseFloat(map.lat),
            lng: parseFloat(map.lng),
        },
        bootstrapURLKeys: {
            key: GOOGLE_MAPS_KEY,
        },
        options: {
            styles: mapTheme,
            // gestureHandling: 'none',
            // disableDefaultUI: true,
        },
    }

    const markerProps = {
        lat: parseFloat(map.lat),
        lng: parseFloat(map.lng),
    }

    return (
        <Map id={'google-map'}>
            <GoogleMapReact {...mapProps}>
                <Marker
                    {...markerProps}
                >
                    <Icon src={mapMarker} />
                </Marker>
            </GoogleMapReact>
        </Map>
    )
}

const Map = styled.div`
    ${tw`absolute inset-0 w-full h-full`};

    &,
    * {
        background-color: unset!important;
        cursor: initial!important;
    }

    button {
        background: none rgb(255, 255, 255)!important;
        cursor: pointer!important;
    }
`

const Marker = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: auto;
    padding: 8px;
    transform: translate(-50%, -100%);
    background-color: white!important;
    /* border-radius: 50%; */
    box-shadow: 2px 2px 5px rgba(12, 28, 32, 0.5);
`

const Icon = styled.img`
    width: 100%;
    height: auto;
`

GoogleMap.propTypes = {
    map: PropTypes.object,
}

export default GoogleMap
